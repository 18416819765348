.product-card {
  background: $white;
  position: relative;
  overflow: hidden;

  img {
    transition: opacity 200ms, transform 300ms ease-out;
    transform-origin: center;
  }

  &-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    padding: $spacer*0.75;
  }

  &-quickview {
    border-radius: 2em;
    border: 1px solid $gallery;
    background: $white;
    line-height: 1.2;
    cursor: pointer;
    opacity: 0;

    .swiper & {
      display: none;
    }
  }

  &-wishlist {
    border-radius: 50%;
    border: 1px solid $gallery;
    background: $white;
    transition: 300ms;
    display: grid;
    place-content: center;
    padding: 8px;

    .filled {
      opacity: 0;
    }

    &:hover {
      background: $red-damask;

      .outline {
        fill: $white;
      }
    }

    &.wishlist-saved {
      border: 1px solid $red-damask;
      background: $white;
      animation: pulse 0.4s ease 0s 2;

      svg {
        animation: shadowPulse 2s;

        @keyframes shadowPulse {
          0% {
            filter: drop-shadow(0 0 0 rgba($red-damask, 1));
          }

          50% {
            filter: drop-shadow(0 0 3px rgba($red-damask, 1));
          }

          100% {
            filter: drop-shadow(0 0 6px rgba($red-damask, 0));
          }
        }
      }

      @keyframes pulse {
        40% {
          transform: scale(1.1);
        }
      }

      .filled {
        opacity: 1;
        fill: $red-damask;
      }

      .outline {
        opacity: 0;
      }

      &:hover {
        .filled {
          opacity: 0;
        }

        .outline {
          opacity: 1;
          fill: $oslo-gray;
        }
      }
    }
  }

  &-percentoff {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 64px;
    height: 64px;
    padding-bottom: 4px;
    text-align: center;
    border-radius: 50%;
    background-color: $red-damask;
    border: 1px solid #FFD9BF;
    color: $white;
    line-height: 0.8;
    font-weight: 500;
    @include set-type(body-small);
    display: grid;
    place-content: center;
    z-index: 11;
    transition: 300ms;

    @include media-breakpoint-down(sm) {
      width: 52px;
      height: 52px;
    }
  }

  &-image {
    aspect-ratio: 432 / 525;

    a {
      display: grid;
      place-items: center;
      grid-template-rows: 100%;
      background: $white;
    }

    &.accessory-image {
      a {
        background: $white;
      }

      .product-card-swatch-image {
        background: $white;
      }
    }
  }

  &-swatch-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 10;
    background: $white;
    transform: scale(1.05);
    opacity: 0;
    pointer-events: none;

    @supports (object-fit: contain) {
      height: 100%;
      object-fit: contain;
    }
  }

  &-badges {
    position: absolute;
    bottom: 0;
    left: 0;

    span {
      + span::before {
        content: ' / ';
      }
    }
  }

  &-details {
    padding-top: $spacer*0.75;
  }


  // Hovering card
  &:hover,
  &:focus {
    .product-card-quickview,
    .product-card-swatch-image {
      opacity: 1;
      transform: scale(1);
      pointer-events: auto;
      z-index: 2;
    }

    .product-card-percentoff {
      opacity: 0;
      pointer-events: none;
    }
  }

  [data-image="product"] & {
    .product-card-swatch-image {
      opacity: 1;
      transform: scale(1);
      pointer-events: auto;
      z-index: 2;
    }

    .product-card-swatch-image + .product-card-lifestyle-image {
      transform: scale(1.05);
      opacity: 0;
      pointer-events: none;
      z-index: 3;
      position: relative;
    }

    &:hover, &:focus {
      .product-card-lifestyle-image {
        transform: scale(1);
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
