.big-menu-section .searchanise-input {
  border-bottom: 1px solid $ebony-clay;
  padding: 10px 0;
  background: none;

  &:focus {
    outline: none;
    border-bottom: 1px solid $red-damask;
    background: none;
  }

  + svg {
    position: absolute;
    right: 0;
    top: 50%;
  }
}

.search-suggestions li {
  cursor: pointer;
}

.search-results {
  height: 100%;
  overflow: auto;
}

.ms-search-results {
  animation: fadeIn 500ms forwards;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    @include set-type(body-small);
    animation: none !important;

    li + li {
      margin-top: 12px;
    }
  }
}

