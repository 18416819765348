.sidelinks-menu {
  position: sticky;
  top: $spacer;
  list-style: none;
  padding: 0;
  margin: 0;
  @include set-type(body-large);

  li + li {
    margin-top: $spacer/2;
  }
}

.jump-section {
  width: 100%;
  padding: $spacer*0.75 $spacer/2;
  border: 1px solid $mercury;
  background: url("../../images/icons/chevron-down.svg") center right 10px no-repeat;
  background-size: 15px;
}
