// Grid settings
$grid-columns: 12;
$grid-gutter-width-base: 32px;
$grid-gutter-width: 32px;

//Spacers
$spacer: 20px;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: () !default;
$spacers: map-merge(
    (
      0: 0,
      '0_25': ($spacer * 0.25),
      '0_5': ($spacer * 0.5),
      '0_75': ($spacer * 0.75),
      1: ($spacer * 1),
      '1_25': ($spacer * 1.25),
      '1_5': ($spacer * 1.5),
      '1_75': ($spacer * 1.75),
      2: ($spacer * 2),
      '2_5': ($spacer * 2.5),
      3: ($spacer * 3),
      '3_5': ($spacer * 3.5),
      4: ($spacer * 4),
      '4_5': ($spacer * 4.5),
      5: ($spacer * 5),
      6: ($spacer * 6)
    ),
    $spacers
);

$container-max-widths: (
  xxs: 1360px
);

$grid-breakpoints: (
  xxs: 0,
  xs: 399px,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1160px,
  xxl: 1360px
);

// Colors
$red-damask:      #DD7F41; // Brand Colour
$ebony-clay:      #1D2328; // Text Colour
$spring-wood:     #F9F8F3;
$white-rock:      #F0EDDD;

$alabaster:       #F9F9F9;
$gallery:         #F0F0F0;
$mercury:         #E5E5E5;
$bombay:          #AAACAF;
$oslo-gray:       #808387;
$rolling-stone:   #707376;
$abbey:           #54585A;
$cape-cod:        #3B3C3D;
$inner-space:     #31383C;
$outer-space:     #252D31;
$bunker:          #0F1921;
$black:           #000;
$white:           #FFF;
$error-red:       #c63a3a; // Errors

// Fonts
$headings-font-family: "Juana", serif;
$font-family-base: "GillSans", sans-serif;

//Displays
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex, grid, inline-grid !default;


// Variables
$link-color: $ebony-clay;
$link-decoration: none;
$link-hover-decoration: none;
$link-hover-color: $ebony-clay;

$headings-font-weight: inherit;
$body-color: $ebony-clay;
