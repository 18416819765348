input, select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border: none;
  border-radius: 0;
  background-color: $white;
  transition: 300ms;
}

input[type='number'] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

select {
  background-image: url("../../../assets/images/icons/chevron-down.svg");
  background-position: center right 15px;
  background-size: 15px;
  background-repeat: no-repeat;
}

input[type="checkbox"], input[type="radio"] {
  width: $spacer;
  min-width: $spacer;
  height: $spacer;
  padding: 0 !important;
  border: 1px solid $mercury;
  background-size: 70%;
  background-position: center;

  &:checked {
    background: url("../../../assets/images/icons/tick-icon.svg") center / 100% no-repeat $red-damask;
    border-color: $red-damask;
  }
}

input[type="radio"] {
  border-radius: 50%;
}

.styled-form {
  .form-row:not(.no-border), div.ginput_container_address, li.gfield:not(.hidden_label):not(.gform_hidden) {
    position: relative;
    border: 1px solid $mercury;
    transition: 300ms;
    margin-bottom: $spacer/2;
    flex: 1 1 100%;

    &:not(.ginput_container_address):focus-within {
      border-color: $oslo-gray;
    }

    input:not([type="checkbox"]):not([type="radio"]), textarea, select {
      padding: 1.5em 0.5em 0.5em;

      &:hover, &:focus {
        background-color: $alabaster;
        outline: none;
      }
    }

    label {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    &.form-row-wide {
      flex: 1 0 100%;
    }


    &.form-row-first, &.form-row-last, &.form-50 {
      @include media-breakpoint-up(sm) {
        flex: 1 0 50%;
      }
    }

    &.form-row-first, &.form-50:not(.end) {
      @include media-breakpoint-up(sm) {
        border-right-style: none;

        &:focus-within {
          + .form-row-last, + .end {
            border-left-color: $oslo-gray;
          }
        }
      }
    }
  }

  label {
    @include set-type(body-micro);
    font-family: $font-family-base;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $oslo-gray;
    line-height: 1;
  }

  .error-notice {
    border: 1px solid $error-red !important;

    label {
      color: $error-red;
    }
  }
}

label[for="shipping_country"],
label[for="billing_country"] {
  & + span strong {
    display: block;
    padding: 1.5em 0.5em 0.5em;
  }
}


.gform_fields {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.gfield_checkbox {
  list-style: none;
  padding: 0 0 0 10px;
  margin: 30px 0 0.5em;
}

.gchoice {
  display: flex;
  align-items: center;

  label {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 10px;
    color: $ebony-clay !important;
  }
}

.ginput_container_address {
  display: flex;
  flex-wrap: wrap;

  .gform-grid-col {
    position: relative;
    display: block;
    border-bottom: 1px solid $mercury;
    flex: 1 0 100%;

    &.address_zip, &.address_country {
      border-bottom: none;
    }

    @include media-breakpoint-up(sm) {
      flex: 1 0 50%;

      &.address_line_1, &.address_city, &.address_zip {
        border-right: 1px solid $mercury;
      }
    }
  }
}

.gform_fileupload_rules {
  padding: 0 8px 8px;
  font-size: 14px;
  display: block;
}

.hidden_label {
  .gfield_label {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
}

.gform_validation_errors {
  h1, h2 {
    @include set-type(body-large);
  }
}

.mailchimp-newsletter {
  border: none !important;

  label {
    display: flex;
    align-items: center;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    margin-bottom: 0;
    text-transform: uppercase;
    @include set-type(body-micro);
    letter-spacing: 0.1em;

    > input {
      margin-right: $spacer*0.75;
    }
  }
}