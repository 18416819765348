.social-share-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $spacer*2;
  height: $spacer*2;
  border: 1px solid $mercury;
  border-radius: 50%;

  &:hover, &:focus {
    svg path {
      fill: $red-damask;
    }
  }

  svg {
    width: auto;
    height: $spacer;

    path {
      fill: $black;
    }
  }
}

.post-nav-button {
  color: $oslo-gray;

  svg {
    transform: translateY(2px);
  }

  &:hover, &:focus {
    color: $red-damask;

    svg path {
      stroke: $red-damask;
    }
  }
}

.post-navigation {
  .prev-link {
    border-right: 1px solid $mercury;
  }

  .next-link {
    border-left: 1px solid $mercury;
  }

  .prev-link + .next-link {
    border-left: none;
  }
}