.single-post .hotspot-holder {
  position: absolute;
  z-index: 1100;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.product-hotspot {
  position: absolute;
  z-index: 2000;
  width: ($spacer * 1.25);
  height: ($spacer * 1.25);
  background: $red-damask;
  border-radius: 50%;
  border: 4px solid $white;
  transform: translate3d(-50%, -50%, 0);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);

  .mini-popup {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 75px);
    width: 150px;
    background: $white;
    padding: 0.5em;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    color: $outer-space;
    line-height: 1.4;
    @include set-type(body-small);
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: -$spacer / 4;
      width: $spacer / 2;
      height: $spacer / 2;
      background: $white;
      transform: translateX(-50%) rotate(45deg);
      border-bottom: rgba(0, 0, 0, 0.5);
      border-right: rgba(0, 0, 0, 0.5);
    }
  }

  &:hover {
    cursor: pointer;

    .mini-popup {
      opacity: 1;
    }
  }
}