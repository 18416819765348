html {
  scroll-behavior: smooth;
}

// Container Max Width
.container {
  width: calc(100% - 32px);
  padding-left: 0;
  padding-right: 0;
  position: relative;

  @include media-breakpoint-up(md) {
    width: calc(100% - #{$spacer*2});
  }

  @include media-breakpoint-up(lg) {
    width: calc(100% - #{$spacer*2.5});
  }

  @include media-breakpoint-up(xl) {
    width: calc(100% - #{$spacer*4});
  }
}

.container-md-e2e {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.container-wide {
  max-width: 1920px;
}

.container-overflow {
  overflow: visible;

  .swiper {
    overflow: visible;
  }
}

// Sizing
.img-fluid, picture.img-fluid img {
  max-width: 100%;
  height: auto;
}

.max-520 {
  max-width: 520px;
}

.max-660 {
  max-width: 660px;
}

.object-cover {
  object-fit: cover;
}

// Defaults
a,
button,
svg * {
  transition: 300ms;
}

// Button Reset
button,
[role="button"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
}

// Misc
.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-static {
  position: static;
}

.bg-rd {
  background-color: $red-damask;
}

.bg-wr {
  background-color: $white-rock;
}

.bg-sw {
  background-color: $spring-wood;
}

.bg-light-grey {
  background-color: $alabaster;
}

.bg-white {
  background-color: $white;
}

.bg-ebony {
  background-color: $ebony-clay;
}

.bg-black {
  background-color: $black;
}

.border-bottom {
  border-bottom: 1px solid $mercury;
}

.border-top {
  border-top: 1px solid $mercury;
}

.border-right {
  border-right: 1px solid $mercury;
}

.border-left {
  border-left: 1px solid $mercury;
}

.border-md-left {
  @include media-breakpoint-up(sm) {
    border-left: 1px solid $mercury;
  }
}

.border-all {
  border: 1px solid $mercury;
}

hr {
  border: none;
  border-top: 1px solid $mercury;
}

.hr-oslo {
  border-top: 1px solid $oslo-gray;
}

.hr-orange {
  border-top: 1px solid $red-damask;
}

.hr-200 {
  width: 200px;
}

.no-style-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

cite {
  font-style: normal;
}

pre {
  background: deeppink;
}

.notice-banner {
  background: $red-damask;
  color: $white;

  &:hover, &:focus {
    color: $white;
    background: darken($red-damask, 5);
  }
}

// Accordion and Tabs
.collapse:not(.show), .tab-pane {
  display: none;
}

.tab-pane.open {
  display: block;
}


// Selection Styling
::-moz-selection { /* Code for Firefox */
  color: white;
  background: $red-damask;
}

::selection {
  color: white;
  background: $red-damask;
}

// Global Styles used on Single Product and Product Listing

.product-add-to-wishlist {
  padding: $spacer/2;
  background: transparent;
  color: $oslo-gray;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 500;
  transition: 300ms;

  svg {
    margin-right: 5px;

    .filled {
      opacity: 0;
    }
  }

  &:hover,
  &:focus {
    color: $red-damask;

    svg {
      .outline {
        opacity: 0;
      }

      .filled {
        opacity: 1;
      }
    }
  }

  &.wishlist-saved {
    color: $red-damask;

    .filled {
      opacity: 1;
    }

    .outline {
      opacity: 0;
    }

    &:hover {
      .filled {
        fill: $oslo-gray;
      }
    }
  }
}

.product-price-pill {
  display: inline-flex;
  align-items: center;
  border: 1px solid $mercury;
  border-radius: 100px;
  background: $white;
  padding: 0 $spacer/2;
  line-height: 1.5;

  .p-from {
    color: $red-damask;
    padding: ($spacer/2);
    border-right: 1px solid $mercury;
    align-self: stretch;
  }

  .p-price {
    padding-left: $spacer/2;
  }

  &.clearance-pill {
    background: $red-damask;

    > span {
      color: $white;
    }

    .p-from {
      color: $white;
      border-color: rgba($mercury, 0.5);
    }
  }
}

// Animations
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  animation: spin 1s infinite linear;
}


#livechat-eye-catcher {
  z-index: 5000 !important;
}

#chat-widget-container {
  z-index: 4999 !important;
}