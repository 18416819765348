/* ==================== Typography ================ */
// Set the type styles that should be used across the codebase, including how they should scale.
// To access type styes, use the 'set-type' mixin with a type style key name as the parameter.
// Eg. set-type(body)

$type-scaling-viewport-max: map_get($grid-breakpoints, 'xxl'); // Set the max viewport size for type scaling
$type-scaling-viewport-min: map_get($grid-breakpoints, 'xs'); // Set the min viewport size for type scaling

// Type styles are defined in a map and take regular CSS properties as key:value pairs.
// Additional properties are available to control type scaling:

// min-font-size : XXpx - sets the minimum size type should reach across the viewport range
// viewport-min: XXXpx, viewport-max: XXXpx - set the min/max viewport size to use for a specific type style
// breakpoints: ( s: XXpx, m: XXpx ) - set incremental scaling sizes for a type style. Map keys must be breakpoint keys.

// Note:
// - Type styles set in rem will be effected by root scaling too.
// - A 'min-font-size' is required to enable font scaling, unless breakpoints are set or type is defined in rem units.

$typography: (
  body-large: (
    font-size : 20px,
    min-font-size : 18px,
  ),
  body-medium: (
    font-size : 18px,
    min-font-size : 15px,
  ),
  body-small: (
    font-size : 15px,
    min-font-size : 14px,
  ),
  body-micro: (
    font-size : 12px,
    min-font-size : 12px,
  ),
  headline: (
    font-size : 80px,
    min-font-size : 54px,
  ),
  h1: (
    font-size : 72px,
    min-font-size : 36px,
  ),
  h1s: (
    font-size : 52px,
    min-font-size : 32px,
  ),
  h1xs: (
    font-size : 34px,
    min-font-size : 28px,
  ),
  h2: (
    font-size : 64px,
    min-font-size : 28px,
  ),
  h3: (
    font-size : 52px,
    min-font-size : 25px,
  ),
  h3s: (
    font-size : 44px,
    min-font-size : 25px,
  ),
  h4: (
    font-size : 28px,
    min-font-size : 22px,
  ),
  h5: (
    font-size : 23px,
    min-font-size : 18px,
  ),
);

@mixin set-font-size( $font-size, $min-font-size, $vmx, $vmm) {
  $viewport-range: $vmx - $vmm;
  $font-size-difference: $font-size - $min-font-size;
  $sizing-increments: $font-size-difference / $viewport-range;
  font-size: $min-font-size;
  @media screen and (min-width: $vmm) {
    font-size: calc(((100vw - #{$vmm}) * #{$sizing-increments}) + #{$min-font-size});
  }
  @media screen and (min-width: $vmx) {
    font-size: $font-size;
  }
}

@mixin set-font-size-breakpoints($font-size, $breakpoints) {
  font-size: $font-size;
  @each $key, $val in $breakpoints {
    @include mq($key) {
      font-size: $val;
    }
  }
}

@mixin outputProps($props) {
  @each $key,
  $value in $props {
    @if ($key !='font-size' and $key !='min-font-size' and $key !='breakpoints' and $key !='type-scaling' and $key !='viewport-max' and $key !='viewport-min') {
      #{$key}: #{$value};
    }
  }
}

@mixin set-type($name) {
  // Check if the is in the fonts config map
  @if map-has-key($typography, $name) {
    $props: map-get($typography, $name);
    $fs: map-get($props, 'font-size');
    $mfs: map-get($props, 'min-font-size');
    $bp: map-get($props, 'breakpoints');
    $vmx: map-get($props, 'viewport-max');
    $vmm: map-get($props, 'viewport-min');

    @if ($vmx == null) {
      $vmx: $type-scaling-viewport-max;
    }

    @if ($vmm == null) {
      $vmm: $type-scaling-viewport-min;
    }

    @if ($mfs != null) {
      @include set-font-size($fs, $mfs, $vmx, $vmm);
    } @else if ($bp != null) {
      @include set-font-size-breakpoints($fs, $bp);
    } @else {
      font-size: $fs;
    }

    @include outputProps($props);

  }
}
