.collection-card {
  display: block;
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
  background-color: $black;

  &-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1;
    will-change: transform;
    transition: ease-out 3s;
  }

  span {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    text-align: center;
    text-shadow: 0 0 25px rgba(0, 0, 0, 0.8);

  }

  &-description {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: $white;
    padding: $spacer;
    z-index: 2;
    opacity: 0;
    transform: translateY(50%);
    transition: 500ms;
    background: linear-gradient(to bottom, transparent, rgba($black, 0.6));
  }

  &:hover, &:focus {
    .collection-card-image {
      opacity: 0.6;
      transform: scale(1.1);
    }

    .collection-card-description {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @include media-breakpoint-down(sm) {
    .collection-card-image {
      opacity: 0.6;
    }
  }

  .swiper-slide & {
    width: 316px;

    @include media-breakpoint-down(md) {
      width: 280px;
      max-width: 80vw;
    }
  }
}
