.blog-category-link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
  text-align: center;
  background-color: $black;
  overflow: hidden;

  &:hover, &:focus {
    color: $white;

    .blog-category-link-image {
      transform: scale(1.05);
      opacity: 0.6;
    }
  }

  &-image {
    z-index: 1;
    will-change: transform;
    transition: 2.5s ease-out;
  }

  &-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
    padding: 0 30px;
  }

  .ms-underline-button {
    position: absolute;
    bottom: 30px;
    z-index: 5;
  }

  @include media-breakpoint-down(sm) {
    &-image {
      opacity: 0.6;
    }
  }

}