.main-header {
  &-top {
    padding: 16px 0 0;

    @include media-breakpoint-up(lg) {
      padding: 24px 0 0;
    }
  }

  &-logo {
    display: inline-block;

    svg {
      max-width: 100%;
      width: 525px;
      height: auto;
      display: inline-block;

      @include media-breakpoint-down(xl) {
        width: 450px;
      }

      @include media-breakpoint-down(md) {
        width: 325px;
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
        max-width: 260px;
      }
    }
  }

  &-bottom {
    padding: 8px 0 12px;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      padding: 16px 0 16px;
    }

    @include media-breakpoint-up(xl) {
      padding: 16px 0 24px;
    }

    body:not(.home) & {
      padding-bottom: 0 !important;
    }
  }

  .search-wrapper {
    padding: 12px 0;
    border-bottom: 1px solid $mercury;

    label {
      line-height: 1;

      svg {
        width: 18px;
        height: auto;
      }
    }

    input {
      outline: none;
      border-bottom: none;
      padding: 0 0 2px 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      border-bottom: none;

      input {
        border-bottom: 1px solid $mercury;
        padding: 4px 0;
      }
    }
  }
}

.js-big-menu-trigger.active {
  color: $red-damask;
}

.mobile-menu-trigger {
  svg {
    width: 26px;
    height: auto;
  }

  svg path {
    fill: $red-damask;
  }

  &.active {
    svg path {
      fill: $rolling-stone;
      transform-origin: center;
    }

    #top, #bottom {
      opacity: 0;
    }

    #middle-a {
      transform: rotate(45deg);
    }

    #middle-b {
      transform: rotate(-45deg);
    }
  }
}

.header-wishlist-basket {
  position: relative;
  align-items: center;
  padding: 6px 0;

  &::before {
    position: absolute;
    top: 0;
    right: -10px;
    width: calc(100% + 20px);
    height: 100%;
    content: '';
    border: 1px solid $mercury;
    border-radius: 100px;
    background: $alabaster;
    opacity: 0;
    z-index: 1;
    transition: 500ms;
  }

  svg {
    position: relative;
    z-index: 2;
  }

  &-hover {
    position: relative;
    z-index: 2;
    max-width: 0;
    opacity: 0;
    border-right: 1px solid $mercury;
    white-space: nowrap;
    transition: max-width 500ms ease, padding 500ms ease, opacity 500ms ease;
  }

  &:hover, &:focus {
    margin-left: 20px !important;

    &::before {
      opacity: 1;
    }

    .header-wishlist-basket-hover {
      max-width: 150px;
      opacity: 1;
      padding: 0 10px 0 5px;
      margin-right: 10px;
    }
  }
}
