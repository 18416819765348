.breadcrumbs {
  display: block;
  border-bottom: 1px solid $mercury;
  text-align: center;
  @include set-type(body-small);
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  span {
    display: inline-flex;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  a {
    display: inline-block;
    color: $oslo-gray;
    padding: 13px 25px 13px 20px;
    background: url("../../images/icons/breadcrumb-chevron.svg") right center no-repeat;

    @include media-breakpoint-down(sm) {
      padding: 10px 20px 10px 15px;
    }

    &:hover {
      color: $red-damask;
    }
  }

  .breadcrumb_last {
    display: inline-block;
    color: $ebony-clay;
    padding: 13px 25px 13px 20px;

    @include media-breakpoint-down(sm) {
      padding: 10px 20px 10px 15px;
    }
  }
}
