.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0, 0, 0);
}

.swiper-pointer-events {
  touch-action: pan-y;

  &.swiper-vertical {
    touch-action: pan-x;
  }
}

.swiper-button-lock {
  display: none;
}

.swiper-slide {
  flex-shrink: 0;
  position: relative;
  transition-property: transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */
.swiper-autoheight {
  &,
  .swiper-slide {
    height: auto;
  }

  .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
}

.swiper-fade {
  &.swiper-free-mode {
    .swiper-slide {
      transition-timing-function: ease-out;
    }
  }

  .swiper-slide {
    pointer-events: none;
    transition-property: opacity;

    .swiper-slide {
      pointer-events: none;
    }
  }

  .swiper-slide-active {
    &,
    & .swiper-slide-active {
      pointer-events: auto;
    }
  }
}



.bar-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  .swiper-pagination-bullet {
    position: relative;
    display: block;
    flex: 0 1 36px;
    height: 2px;
    background-color: $bombay;
    cursor: pointer;
    transform-origin: bottom center;
    transition: 200ms;

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: -2px;
      bottom: -2px;
      width: 100%;
    }

    &:hover, &:focus {
      transform: scaleY(1.5);
    }

    &-active {
      background-color: $red-damask;
      transform: scaleY(2);
      flex: 0 1 60px;

      &:hover, &:focus {
        transform: scaleY(2);
      }
    }
  }
}
