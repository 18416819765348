.slideout-body_basket {
  display: flex;
  flex-direction: column;
}

.basket-items-wrapper {
  flex-grow: 1;
  flex-basis: 0;
  overflow: auto;

  &.in-basket-slideout {
    padding-bottom: 80px;
    padding-right: 20px;
    margin-right: -20px;

    .basket-item-price {
      @include media-breakpoint-down(xs) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }
}

.basket-item {
  position: relative;
  display: flex;

  & + & {
    margin-top: $spacer;

    @include media-breakpoint-down(xs) {
      padding-top: $spacer;
      border-top: 1px solid $mercury;

      .basket-item-remove {
        top: $spacer;
      }
    }
  }

  &-image {
    margin-right: $spacer;
  }

  &-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &-remove {
    position: absolute;
    top: 0;
    right: 0;

    &:hover, &:focus {
      svg {
        path {
          fill: $black;
        }
      }
    }
  }

  &-amounts {
    @include media-breakpoint-down(xs) {
      > div:not(.basket-item-price) {
        flex-basis: 50%;
        max-width: calc(50% - 10px);
      }
    }
  }

  &-qty-wrap {
    border: 1px solid $mercury;
    display: flex;
    padding: 5px 10px;
    justify-content: space-between;
  }

  .qty-button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 10px;
      height: auto;

      path {
        fill: $ebony-clay;
      }
    }
  }

  input[type="number"] {
    width: 50px;
    text-align: center;
    -moz-appearance: textfield;
    appearance: textfield;
    border: none;

    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &-qty-sample {
    display: flex;
    align-items: center;
  }
}

.gradient-before {
  position: relative;

  &::before {
    // Fade out gradient to overflowing basket items
    content: '';
    position: absolute;
    bottom: calc(100% + 1px);
    left: -1px;
    right: -1px;
    height: 100px;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba($white, 0), rgba($white, 1) calc(100% - 20px));
  }
}

.basket-delivery-estimate {
  position: relative;
  border: 1px solid $mercury;

  input {
    border: none;

    &:focus {
      outline: none;
    }
  }

  .js-delivery-estimate-submit {
    border-left: 1px solid $mercury;
    padding-left: $spacer/2;
  }
}

.basket-totals-table {
  width: 100%;

  td, th {
    padding: 0;
  }

  td {
    text-align: right;
  }

  tr.subtotal {
    @include set-type(body-micro);
    color: $oslo-gray;

    th {
      font-weight: normal;
    }
  }

  tr.total {
    font-weight: $font-weight-bold;
    color: $black;

    td, th {
      padding-top: 5px;
    }
  }

}
