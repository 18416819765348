.woocommerce-error {
  list-style: none;
  background: $alabaster;
  padding: $spacer;
  border-top: 2px solid $error-red;
  margin: $spacer 0;

  .my-account & {
    @include media-breakpoint-up(xl) {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.woocommerce-password-strength {
  padding: $spacer/2;
  border-top: 1px solid $mercury;
  @include set-type(body-micro);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  &.strong {
    background-color: #3f8e2b;
    color: $white;
    font-weight: $font-weight-normal;
  }

  &.short {
    background-color: $error-red;
    color: $white;
  }

  &.bad {
    background-color: #F49D19;
  }

  &.good {
    background-color: #C3D04D;
  }
}

.woocommerce-password-hint {
  display: block;
  padding: $spacer/2;
  background: $white;
}