.newsletter-signup-form {
  position: relative;

  input {
    width: 100%;
    padding: 10px 0;
    line-height: 1;
    background: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $red-damask;

    &:focus {
      outline: none;
      background: transparent;
      border-bottom-color: $black;
    }
  }

  button.submit {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 10px;
    background: url("../../images/icons/chevron-right-orange.svg") center no-repeat;
    background-size: 8px 14px;
    font-size: 0;
    color: transparent;
  }
}