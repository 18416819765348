// Grouped styles

.ms-button, .ms-small-button, .ms-arrow-button, .ms-underline-button {
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  transition: 300ms;

  &.w-100 {
    justify-content: center;
  }
}

.ms-button {
  padding: 1.1em 2em;
  @include set-type(body-small);
  font-weight: 500;
  background-color: $red-damask;
  color: white;
  border: 1px solid $red-damask;

  &.medium-button {
    padding: 0.6em 1.5em;
    font-size: 12px;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.button-secondary {
    background-color: $white;
    border-color: $mercury;
    color: $ebony-clay;

    &.transparent {
      background-color: transparent;

      &:hover, &:focus {
        background-color: $ebony-clay;
        color: $white;
      }
    }

    // On dark bgs
    &.invert {
      background-color: transparent;
      border-color: $white;
      color: $white;

      &:hover, &:focus {
        background-color: $white;
        color: $ebony-clay;
        border-color: $white;
      }
    }
  }

  &.button-tertiary {
    border-color: 1px solid $red-damask;
    background-color: rgba($red-damask, 0.05);
    color: $ebony-clay;

    &:hover, &:focus {
      border-color: 1px solid $red-damask;
      background-color: $red-damask;
      color: $white;
    }
  }

  &:hover {
    background-color: $ebony-clay;
    color: $white;
    border-color: $ebony-clay;
  }

  // On dark bgs
  &.invert {
    &:hover, &:focus {
      background-color: $white;
      color: $ebony-clay;
      border-color: $white;
    }
  }

  &.add-to-basket-disabled {
    background: rgba($red-damask, 0.05);
    color: $ebony-clay;

    &:hover,
    &:focus {
      background: rgba($red-damask, 0.05);
      color: $ebony-clay;
      border-color: $red-damask;
    }
  }
}

.ms-small-button {
  padding: 0.5em 1em;
  line-height: 1.1;
  @include set-type(body-micro);
  border-radius: 2em;
  background-color: transparent;
  border: 1px solid $ebony-clay;

  // On dark bgs
  &.invert {
    border-color: $white;
    color: $white;
  }

  &:hover {
    background-color: $red-damask;
    color: $white;
    border-color: $red-damask;
  }

  &.orange-button {
    background-color: $red-damask;
    border-color: $red-damask;
    color: $white;

    svg {
      width: auto;
      height: 1.5em;
      margin-right: 1em;

      path {
        fill: $white;
      }
    }

    &:hover, &:focus {
      background-color: $ebony-clay;
      color: $white;
      border-color: $ebony-clay;
    }
  }

  &.springwood-button {
    background-color: $spring-wood;
    border-color: $spring-wood;
    color: $body-color;

    &:hover, &:focus {
      background-color: $red-damask;
      border-color: $red-damask;
      color: $white;
    }
  }
}

.ms-tiny-button {
  display: inline-block;
  font-size: 15px; // rare occasion where its fixed
  border-radius: 2em;
  background: $white;
  padding: 4px 12px 6px;
  line-height: 1;
  transition: 300ms;

  &:hover, &:focus {
    background: $red-damask;
    color: $white;
  }
}

.ms-arrow-button {
  @include set-type(body-small);
  font-weight: 500;
  padding-right: 5px;

  &::after {
    content: '';
    width: 0.6em;
    min-width: 0.6em;
    height: 0.6em;
    margin-left: 0.75em;
    margin-top: 1px;
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
    transition: 300ms;
    will-change: transform;
  }

  &.arrow-left {
    flex-direction: row-reverse;

    &::after {
      transform: rotate(-135deg);
      margin-right: 0.75em;
      margin-left: 0;
    }

    &:hover, &:focus {
      &::after {
        border-color: $red-damask;
        transform: translateX(-5px) rotate(-135deg);
      }
    }

  }

  // On dark bgs
  &.invert {
    color: $white;
  }

  &:hover, &:focus {
    &::after {
      border-color: $red-damask;
      transform: translateX(5px) rotate(45deg);
    }
  }
}

.ms-underline-button {
  @include set-type(body-small);
  text-decoration: none;
  font-weight: 500;
  padding-bottom: 0.2em;
  border-bottom: 1px solid $ebony-clay;

  // On dark bgs
  &.invert {
    color: $white;
    border-bottom: 1px solid $white;
  }

  &:hover, &:focus {
    border-bottom: 1px solid $red-damask;
  }
}
