.project-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;

  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.project-grid-box {
  grid-column: span 1;
  grid-row: span 1;

  &:nth-child(18n - 13), &:nth-child(18n - 2) {
    grid-column: span 2;
    grid-row: span 2;

    .project-card-image img {
      aspect-ratio: 672 / 848;
    }
  }
}

.project-card {
  width: 100%;
  position: relative;
  overflow: hidden;

  &-btn {
    z-index: 9;
    position: absolute;
    top: 16px;
    left: 16px;
    border-radius: 20px;
    opacity: 0;
    transition: 0.3s;
    padding: 0.5em 1.25em;
    color: $red-damask;
    border: solid 1px #F0F0F0;
    background: $white;
  }

  &-image {
    transform: scale(1);
    transition: 0.4s;

    img {
      aspect-ratio: 328 / 416;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &:hover {
    cursor: pointer;

    .project-card-image {
      transform: scale(1.15);
    }

    .project-card-btn {
      opacity: 1;
      background-color: $red-damask;
      color: $white;
    }
  }
}

.project-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 99999;
  opacity: 0;
  transition: 300ms;

  &.active {
    display: flex;
    opacity: 1;
  }

  .close {
    position: absolute;
    right: 16px;
    top: 16px;
    border: solid 1px $mercury;
    border-radius: 200px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    svg {
      width: 14px;
      height: 14px;
    }

    &:hover {
      cursor: pointer;
    }

    @include media-breakpoint-up(lg) {
      top: 24px;
      right: 24px;
    }
  }

  &-content {
    background: $white;
    height: 90vh;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 8px 16px 32px rgba(0, 0, 0, 0.3);

    @include media-breakpoint-up(md) {
      max-height: 90vh;
      height: auto;
    }

    .product-card-quickview {
      display: none;
    }
  }

  &-image {
    aspect-ratio: 680/880;
    background: $alabaster;
    max-height: 90vh;
    width: 100%;

    @include media-breakpoint-down(sm) {
      aspect-ratio: 680/800;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &-text {
    overflow: auto;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-header {
    position: sticky;
    top: 0;
    background: $white;
  }

  .arrows {
    position: absolute;
    bottom: 16px;
    right: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    @include media-breakpoint-up(md) {
      bottom: 24px;
      right: 24px;
    }

    .left-arrow, .right-arrow {
      border: solid 1px $mercury;
      border-radius: 200px;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;

      &.disabled svg {
        opacity: 0.5;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .product-card-wishlist {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 6px;
  }
}


.project-modal-products {
  overflow: auto;

  @include media-breakpoint-down(md) {
    display: none;
  }

  .product-card {
    height: auto !important;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    transition: 500ms;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
  }

  &.single-col {
    max-width: 432px;
    width: 100%;

    .project-modal-product {
      max-height: 100%;
      aspect-ratio: 1 / 1.7;
      max-width: 100%;
    }
  }

  &.two-col {
    max-width: 528px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 16px;
    padding-bottom: 60px;

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  }
}

@include media-breakpoint-down(md) {
    .project-modal-header + img {
        max-height: 55vh;
        max-width: 100%;
        width: fit-content;
    }
}
