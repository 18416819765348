html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

@font-face {
  font-family: "Juana";
  font-weight: 400;
  font-display: swap;
  src:
      url("../../fonts/6097332/f8b6a587-5835-451d-b94b-7384ca6f6ac5.woff2") format("woff2"),
      url("../../fonts/6097332/33a68b70-c148-4366-af6b-a42741619193.woff") format("woff");
}

@font-face {
  font-family: "Juana";
  font-weight: 500;
  font-display: swap;
  src:
      url("../../fonts/6097340/4ee2cd34-c5bb-4754-91b4-7f511f9dc7d8.woff2") format("woff2"),
      url("../../fonts/6097340/90a86520-36f3-47ef-a3c2-63e555d7f415.woff") format("woff");
}

@font-face {
  font-family: "GillSans";
  font-weight: 400;
  font-display: swap;
  src:
      url("../../fonts/1603210/43b67b68-8704-4bc0-9c41-620c664cb9dd.woff2") format("woff2"),
      url("../../fonts/1603210/37a7a423-c7fe-48f9-8013-63d2cc236c7a.woff") format("woff");
}

@font-face {
  font-family: "GillSans";
  font-weight: 500;
  font-display: swap;
  src:
      url("../../fonts/1603258/54b694e4-27f0-465e-9bc4-6639d5bf709a.woff2") format("woff2"),
      url("../../fonts/1603258/fbbea18d-5f65-424f-8883-dadcefcc4ef8.woff") format("woff");
}

// Sizing
body, .body-normal-size {
  @include set-type(body-medium);
  line-height: 130%;
}

.body-large-size {
  @include set-type(body-large);

  &:not(.serif).caps {
    font-weight: 500;
  }
}

.body-small-size {
  @include set-type(body-small);

  &:not(.serif).caps {
    font-weight: 500;
  }
}

.body-micro-size {
  @include set-type(body-micro);
}

.serif.caps:not(.body-large-size):not(.body-small-size) {
  font-weight: 500;
}

h1,.h1,.h1s,.h1xs,
h2,.h2,
h3,.h3,.h3s,
h4,.h4,
h5,.h5,
h6,.h6 {
  font-family: $headings-font-family;
  line-height: 130%;
  font-weight: 400;
}

.headline {
  @include set-type(headline);
  line-height: 105%;
  font-weight: 500;
}

h1, .h1 {
  @include set-type(h1);
  line-height: 120%;
  font-weight: 500;
}

.h1s {
  @include set-type(h1s);
}

.h1xs {
  @include set-type(h1xs);
}

h2, .h2 {
  @include set-type(h2);
}

h3, .h3 {
  @include set-type(h3);
}

.h3s {
  @include set-type(h3s);
}

h4, .h4 {
  @include set-type(h4);
  font-weight: 500;

  &.caps {
    font-weight: 400;
  }
}

h5, .h5 {
  @include set-type(h5);
  font-weight: 500;
}

// Misc
.strong, strong {
  font-weight: 500;
}

.book {
  font-weight: $font-weight-normal;
}

.semi {
  font-weight: 500;
}

.strikethrough {
  text-decoration: line-through;
}

.caps {
  text-transform: uppercase;
  letter-spacing: 0.1em;

  &-narrow {
    letter-spacing: 0;
  }
}

.serif {
  font-family: $headings-font-family;
}

.sans-serif {
  font-family: $font-family-base;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

a.no-style-link {
  color: inherit !important;
  text-decoration: none !important;

  &:hover, &:focus {
    color: inherit;
    text-decoration: none;
  }
}

.hover-underline {
  &:hover, &:focus {
    text-decoration: underline;
    color: inherit;
  }
}

.lh-1 {
  line-height: 1;
}

.lh-1_2 {
  line-height: 1.2;
}

.lh-1_3 {
  line-height: 1.3;
}

.nowrap {
  white-space: nowrap;
}

// Entry Content Styles
.entry-content {
  h2, h3, h4, h5 {
    margin-bottom: $spacer;

    &:not(:first-child) {
      margin-top: $spacer*1.5;
    }
  }

  h2 {
    @include set-type(h4);
  }

  h3 {
    @include set-type(h5);
  }

  p, ul, ol {
    margin-bottom: 1em;
    line-height: 160%;
    @include set-type(body-large);
  }

  blockquote {
    padding-left: 1em;
    border-left: 1px solid $red-damask;
    font-family: $headings-font-family;
    font-weight: 500;
    line-height: 130%;
    @include set-type(h5);
  }

  a:not(.ms-button, .ms-small-button, .ms-arrow-button, .ms-underline-button) {
    color: $red-damask;
    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  mark {
    background-color: $spring-wood;
    color: $red-damask;
  }

  p, ul, ol, blockquote {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .two-col {
    @include media-breakpoint-up(sm) {
      column-count: 2;
      column-gap: $spacer*1.75;

      p {
        -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
        page-break-inside: avoid; /* Firefox */
        break-inside: avoid;
      }
    }

    p, ul, ol {
      @include set-type(body-medium);
    }
  }
}

.ms-notice {
  background: rgba($red-damask, 0.05);
  border: 1px solid $red-damask;
  border-radius: 5px;
  margin: $spacer*1.5 0;

  &-heading {
    flex-shrink: 0;
    @include media-breakpoint-up(sm) {
      border-right: 1px solid $red-damask;
    }
  }

  p {
    line-height: 130%;
    @include set-type(body-small);
  }

  p:last-child {
    margin-bottom: 0;
  }
}

// Colour Classes
.colour-oslo-gray {
  color: $oslo-gray;
}

.colour-bombay {
  color: $bombay;
}

.colour-stone {
  color: $rolling-stone;
}

.colour-abbey {
  color: $abbey;
}

.colour-damask {
  color: $red-damask;
}

.colour-white {
  color: $white;
}


