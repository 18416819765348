body.modal-open {
  overflow: hidden;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($ebony-clay, 0.8);
    z-index: 4999;
    animation: fadeIn 200ms;
  }
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 300ms;
  overscroll-behavior: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  border: solid 1px $white;
  background: $white;
  border-radius: 200px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  svg {
    width: 14px;
    height: 14px;
  }
}

.quicklook-modal-gallery-sizer {
  position: relative;
  height: calc(87vh - 250px);
  overflow: hidden;
}

.quicklook-modal-carousel {
  height: 100%;
  position: relative;

  .swiper-wrapper {
    height: 100%;
  }

  .swiper-navigation {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: $spacer/2;
    display: flex;
    justify-content: space-between;
    z-index: 10;

    button {
      width: 36px;
      height: 36px;
      background: $white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover, &:focus {
        svg path {
          stroke: $red-damask;
        }
      }
    }
  }
}

.quicklook-modal-slide {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// Hide wishlist in quicklook on wishlist page
body.my-account.wish-list #modal-quickview {
  .quicklook-product-badge:last-of-type {
    padding-right: 0 !important;
    margin-right: 0 !important;
    border-right: none !important;
  }

  .product-add-to-wishlist {
    display: none;
  }
}