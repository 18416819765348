/**
 * WordPress Generated Classes
 * @see http://codex.wordpress.org/CSS#WordPress_Generated_Classes
 */

/** Media alignment */
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}

.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}

.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}

@include media-breakpoint-up(sm) {
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }

  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}



/** Text meant only for screen readers */
.screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}