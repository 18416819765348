.shop_table, .account-table {
  border: 1px solid $mercury;
  width: 100%;

  a:not(.ms-button) {
    color: $ebony-clay;
  }

  td, th {
    border-top: 1px solid $mercury;
    padding: $spacer*0.75;

    &:last-child {
      text-align: right;
    }
  }

  th {
    font-weight: 500;
  }

  .includes_tax, .tax_label, .shipped_via {
    display: none;
  }
}

.woocommerce-checkout .order-totals-table {
  tr.woocommerce-shipping-totals, tr.fee {
    display: none;
  }

  .order-total, .total {
    td, th {
      background: $ebony-clay;
      color: $white;
    }
  }
}


.woocommerce .blockUI.blockOverlay {
  position: relative;
  opacity: 1 !important;
  background: rgba($white, 0.6) !important;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    animation: spin 1s infinite;
    background: url("../../images/loading-spinner.svg") center center;
  }
}