body.slideout-open {
    overflow: hidden;

    &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($ebony-clay, 0.6);
        z-index: 4999;
        animation: fadeIn 200ms;
    }
}

.slideout-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    z-index: 5000;

    transition: 300ms;
    overscroll-behavior: none;

    &.visible {
        opacity: 1;
        pointer-events: auto;

        .slideout-container {
            transform: translateX(0);
        }

        &.loading .slideout-container::before {
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 50px;
            height: 50px;
            margin-top: -10px;
            margin-left: -10px;
            background-image: url('../../../assets/images/loading-spinner.svg');
            animation: spin 1s infinite linear;
        }
    }
}

.slideout-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-width: 650px;
    background: $white;
    display: flex;
    flex-direction: column;
    transform: translateX(50%);
    transition: 300ms;
}

.slideout-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: $spacer $spacer 0;
    margin-bottom: $spacer;

    @include media-breakpoint-up(md) {
        padding: ($spacer*1.5) ($spacer*2) 0;
    }
}

.slideout-close {
    margin-left: auto;

    svg {
        width: $spacer;
        height: $spacer;

        path {
            stroke: $ebony-clay;
        }
    }

    &:hover, &:focus {
        svg path {
            stroke: $red-damask;
        }
    }
}

.slideout-body {
    flex-grow: 1;
    overflow: auto;
    padding: $spacer $spacer;

    @include media-breakpoint-up(md) {
        padding: ($spacer*1.5) ($spacer*2);
    }
}

.slideout-icon {
    svg, img {
        width: 108px;
        height: auto;
    }
}


.remove-recipient {
    position: absolute;
    top: calc(50% - 5px);
    right: 10px;
    transform: translateY(-50%);
    border-radius: 50%;
    background: $gallery;
    width: 25px;
    height: 25px;

    &:hover, &:focus {
        background: $red-damask;
        color: $white;
    }

    .original & {
        display: none;
    }
}
