body.big-menu-open {
  height: 100%;
  overflow: hidden;
}

.big-menu-wrap {
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100vh - 120px);
  height: calc((var(--vh, 1vh) * 100) - (var(--headerHeight, 120px)));
  overflow: hidden;
  background: rgba($black, 0.5);

  z-index: -1;
  opacity: 0;
  pointer-events: none;
  transition: 500ms;

  @include media-breakpoint-down(md) {
    height: calc(100vh - 100px);
    height: calc((var(--vh, 1vh) * 100) - (var(--headerHeight, 110px)));
  }

  body:not(.home) & {
    // Mimics the padding from the home page version of main-header-bottom
    border-top: 12px solid $white;

    @include media-breakpoint-up(sm) {
      border-top: 16px solid $white;
    }

    @include media-breakpoint-up(xl) {
      border-top: 24px solid $white;
    }
  }

  &.visible {
    z-index: 90000; //Sits above everything
    opacity: 1;
    pointer-events: auto;
  }
}

.big-menu-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  overscroll-behavior-y: contain;
  -ms-scroll-chaining: none;

  &.active {
    opacity: 1;
    pointer-events: auto;

    .navigation-level-1 {
      transform: translateX(0);
    }
  }

  li > a:not(.nav-pre-title),
  .search-suggestions li {
    font-size: 17px;
    font-weight: 500;
    display: inline-block;
    width: 100%;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      font-weight: 400;
    }

    &:hover, &:focus {
      color: $red-damask;
      text-decoration: none;
    }
  }

  li.menu-item-has-children {
    > a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: auto;

      &::after {
        content: '';
        width: 6px;
        height: 9px;
        background: url("../../images/icons/chevron-right-dark.svg") center / contain no-repeat;
      }
    }
  }

  li.shop-large {
    a, button {
      font-size: 24px;
      font-weight: 400;
    }

    &::after {
      width: 8px;
      height: 12px;
    }

    + li {
      margin-top: 20px;
    }
  }

  li.shop-clearance {
    a {
      color: $red-damask;
      border-bottom: 2px solid $red-damask;
      padding-bottom: 4px;
      width: auto;

      &:hover, &:focus {
        border-bottom: 2px solid $ebony-clay;
      }
    }
  }
}

.navigation-section {
  position: relative;
  height: 100%;
  display: flex;
}

// Level 1 & 2 shared
.navigation-level-1,
.navigation-level-2 {
  position: absolute;
  top: 0;
  left: 0;
  padding: 32px 40px 48px;
  width: 33.33%;
  height: 100%;
  background: $spring-wood;
  transition: 500ms;
  overflow: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    transition: 500ms;
  }

  /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
  &::-webkit-scrollbar-thumb:vertical {
    width: 8px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(red, 0);
  }

  &::-webkit-scrollbar-thumb:vertical:active {
    background: rgba(0, 0, 0, 0.61); /* Some darker color when you click it */
    -webkit-border-radius: 100px;
  }

  @include media-breakpoint-down(lg) {
    padding: 32px;
  }

  @include media-breakpoint-down(md) {
    padding: 24px;
    width: 50vw;
  }

  @include media-breakpoint-down(sm) {
    width: 100vw;
    transform: translateX(0);
  }
}

// Level 1
.navigation-level-1 {
  z-index: 10;
  transform: translateX(-33.33333vw);

  @include media-breakpoint-down(md) {
    transform: translateX(0);
  }

  &.right-side {
    @include media-breakpoint-up(lg) {
      left: auto;
      right: 0;
      transform: translateX(33.33333vw);
    }
  }

  &.navigation-level-shop, &.navigation-inspiration {
    @include media-breakpoint-up(lg) {
      padding: 32px 40px 64px;
      transform: none;
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  &.search-suggestions {
    @media (min-width: 1440px) {
      padding-left: calc(50vw - (1360px / 2));
    }
  }

  > ul {
    list-style: none;
    margin: 0;
    padding: 0 0 20px;

    > li {
      + li {
        margin-top: 16px;
      }

      &.active > a {
        color: $red-damask;
        text-decoration: none;
      }
    }

    .sub-menu {
      display: none;
    }
  }
}

// Level 2
.navigation-level-2 {
  pointer-events: none;
  transform: translateX(-25vw);
  z-index: 15;
  opacity: 0;

  @include media-breakpoint-down(md) {
    transform: translateX(0);
  }

  &.right-side {
    @include media-breakpoint-up(lg) {
      left: auto;
      right: 0;
      transform: translateX(33.33333vw);
    }
  }

  &.search-results-wrapper {
    left: 33.33%;
    border-left: 1px solid $mercury;

    @include media-breakpoint-down(md) {
      left: 50%;
    }

    @include media-breakpoint-down(sm) {
      left: 0;
      border-left: none;
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(0);

    @include media-breakpoint-down(md) {
      .sub-menu-holder {
        pointer-events: none;
      }
    }
  }

  &.mobile-pointers-on {
    z-index: 12;

    .sub-menu-holder {
      pointer-events: auto;
    }
  }

  .sub-menu {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      animation: fadeIn 500ms forwards;

      li {
        a {
          display: inline-block;
        }

        + li {
          margin-top: 16px;
        }
      }
    }
  }
}

.navigation-level-1-close,
.navigation-level-2-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  display: none;
  align-items: center;
  justify-content: center;
  background: $red-damask;
  border-radius: 50%;
  z-index: 2;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  svg path {
    stroke: $white;
  }
}

.nav-shop-grid {
  display: flex;
  justify-content: space-between;
  gap: 32px;

  @include media-breakpoint-up(xl) {
    &-item {
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(6) {
        border-left: 1px solid $mercury;
        padding-left: 32px;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    gap: 40px;
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;

    &-item {
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(6) {
        padding-left: 40px;
      }
    }
  }
}

.nav-inspiration-grid ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 24px 16px;

  li {
    text-align: center;
    flex: 1 1 calc(50% - 8px);
    max-width: calc(50% - 8px);

    img {
      transition: 0.4s;
      will-change: transform;
    }

    p {
      transition: 300ms;
    }

    &:hover {
      img {
        transform: scale(1.15);
      }
    }

    a:hover, a:focus {
      color: $ebony-clay !important;

      > p {
        color: $red-damask;
      }
    }
  }


  @include media-breakpoint-up(lg) {
    gap: 24px;

    li {
      flex: 1 1 0;
      max-width: none;
    }
  }

  @include media-breakpoint-up(xxl) {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
  }
}

.shop-sub-menu {
  list-style: none;
  padding: 0;
  margin: 16px 0 0;

  li + li {
    margin-top: 12px;
  }
}

.nav-pre-title, .nav-back {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 14px;
  font-weight: 500;
  color: $red-damask;

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.nav-back {
  display: flex;
  align-items: center;
  color: $body-color;
}

// Showroom Details SubMenu
.big-menu-showroom-details {
  p {
    font-size: 14px;
  }
}