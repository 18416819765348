.category-hero {
  &-description {
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    a {
      color: $red-damask;
      text-decoration: underline;
    }

    .read-more {
      display: none;
      color: $oslo-gray;
      @include set-type(body-small);
      background: linear-gradient(to right, rgba($white, 0) 0, rgba($white, 1) 15px);
      padding: 0 0 0 20px;
    }

    &.clamped .read-more {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &.unclamped {
      display: block;
      -webkit-line-clamp: none;
      line-clamp: none;
    }
  }
}

.category-footer {
  &-description {
    a {
      color: $red-damask;
      text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
      position: relative;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &.clamped {
      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 10px);
        height: 80px;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba($white, 0), rgba($white, 1) 90%);
        pointer-events: none;
        transition: 300ms;
      }
    }

    &.unclamped {
      display: block;
      -webkit-line-clamp: none;
      line-clamp: none;

      + .category-footer-more {
        display: none;
      }
    }
  }

  &-more {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    transition: 300ms;
  }

  &-image-column {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.category-header-links {
  .ms-arrow-button {
    max-width: 160px;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 80px;
    left: $spacer*2;
    right: $spacer*2;
  }

  @include media-breakpoint-up(xl) {
    top: 80px;
    left: $spacer*4;
    right: $spacer*4;
  }
}