@include media-breakpoint-up(sm) {
  .main-footer .footer-accordion-toggle {
    pointer-events: none;
  }

  .main-footer .collapse {
    display: block !important;
    height: auto !important;
  }
}

.main-footer {
  border-top: 1px solid $mercury;
}

.footer-pre-title {
  font-size: 14px;
  font-weight: 500;
}

.footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    color: $ebony-clay;

    + li {
      margin-top: 12px;
    }

    a {
      color: inherit;
      font-size: 17px;
      font-weight: 500;

      @include media-breakpoint-down(sm) {
        font-weight: 400;
      }

      &:hover,
      &:focus {
        //color: $ebony-clay;
      }
    }
  }
}

.footer-legal {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;

  li + li {
    margin-left: 30px;

    @include media-breakpoint-down(sm) {
      margin-left: 12px;
    }
  }
}

.footer-socials {
  display: flex;
  flex-wrap: wrap;

  li + li {
    margin-left: 20px;
    margin-top: 0;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: auto;
      height: 22px;

      path {
        fill: $red-damask;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .footer-accordion-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid $mercury;
    padding: $spacer*0.75 0;
    font-weight: 500;

    &[data-target="footerCategories"] {
      border-top: none;
    }

    &::after {
      content: '';
      width: 6px;
      height: 9px;
      background: url("../../images/icons/chevron-right-dark.svg") center / contain no-repeat;
      transform: rotate(90deg);
      transition: 300ms;
    }

    &.expanded {
      &::after {
        transform: rotate(-90deg);
      }
    }
  }
}


.lc-trigger-hold {
  position: fixed;
  bottom: 16px;
  right: 16px;
  opacity: 0;
  z-index: 4999;

  &.show {
      animation: fadeIn 300ms forwards 2s;
  }

  svg {
    width: 100%;
    height: auto;
  }

  .widget {
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    z-index: 2;
  }

  .eye-catcher {
    position: absolute;
    z-index: 1;
    bottom: calc(100% - 15px);
    right: calc(75% - 15px);
    padding: 15px;
    width: calc(302px + 30px);
    max-width: 90vw;
    height: auto;
    filter: drop-shadow(2px 4px 16px rgba(0, 0, 0, 0.16));
    transform-origin: bottom right;
    transform: translateY(30%) scale(0.5);
    transition: 200ms;

    opacity: 0;
    pointer-events: none;
  }

  &.show-eye-catcher {
    .eye-catcher {
      transform: translateY(0) scale(1);
      opacity: 1;
      pointer-events: auto;
    }

    &:hover {
      .lc-eye-catcher-close {
        opacity: 1;
      }
    }

    @include media-breakpoint-down(sm) {
      .lc-eye-catcher-close {
        opacity: 1;
      }
    }
  }
}

.lc-eye-catcher-close {
  position: absolute;
  top: -180px;
  right: 20px;
  width: 24px;
  height: 24px;
  background: $red-damask;
  padding: 6px;
  border-radius: 50%;
  opacity: 0;
  display: flex;
  align-items: center;
  box-shadow: 3px 6px 12px 0 rgba(0, 0, 0, 0.16);
  z-index: 20;

  &:hover {
    background: darken($red-damask, 5);
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
