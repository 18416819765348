// Default Product Carousel Styles


.ms-carousel {
  .swiper-control-wrapper {
    display: flex;
    align-items: center;

    .swiper-button-next, .swiper-button-prev {
      flex-shrink: 0;
    }
  }

  .swiper-scrollbar {
    flex-grow: 1;
    height: 1px;
    background: $mercury;

    &-drag {
      height: 1px;
      background: $red-damask;
    }
  }
}

.product-card-slide {
  width: 316px;

  @include media-breakpoint-down(md) {
    width: 280px;
    max-width: 80vw;

    &-small {
      width: 170px;
      max-width: 60vw;

      .product-card {
        padding: $spacer/2;
      }
    }
  }
}

.project-card-slide {
  width: 316px;

  @include media-breakpoint-down(md) {
    width: 280px;
    max-width: 80vw;
  }
}

.my-mandarin-carousel {
  .swiper-wrapper {
    justify-content: center;
  }
}

.post-card-slide {
  width: 500px;

  @include media-breakpoint-down(md) {
    width: 280px;
    max-width: 80vw;
  }
}
