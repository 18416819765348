body#tinymce {
  margin: 20px !important;

  blockquote {
    padding-left: 1em;
    border-left: 1px solid $red-damask;
    font-family: $headings-font-family;
  }

  a {
    color: $red-damask;
  }
}